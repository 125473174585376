import { MutableRefObject } from 'react';

import { ModalHeader } from '../modal-header';

interface ModalStructureProps {
  title?: string;
  onClose?: () => void;
  children?: React.ReactNode;
  modalRef: MutableRefObject<HTMLDivElement | null>;
}

export const ModalStructure: React.FC<ModalStructureProps> = ({
  title,
  onClose,
  children,
  modalRef
}) => {
  return (
    <div className="modal-holder">
      <div
        ref={modalRef}
        className="popup-holder"
      >
        <ModalHeader title={title} onClose={onClose} />
        {children}
      </div>
    </div>
  );
};
