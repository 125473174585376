import React, { useRef, useState, useEffect, useCallback } from 'react';

import { MODAL_CONTAINER_ID } from '../../../containers';
import { useOutsideClick } from '../../../../hooks';
import { ModalStructure } from './modal-structure';
import { noop } from '../../../../constants';
import { createPortal } from 'react-dom';

interface ModalProps {
  title?: string;
  onClose?: () => void;
  closeOnOutsideClick?: boolean;
  children?: React.ReactNode;
}

export const Modal: React.FC<ModalProps> = ({
  title,
  children,
  onClose = noop,
  closeOnOutsideClick = true,
}) => {

  const modalRef = useRef<null | HTMLDivElement>(null);

  const [ modalContainer, setModalContainer ] = useState<HTMLElement | null>(null);

  useEffect(() => {
    setModalContainer(document.getElementById(MODAL_CONTAINER_ID));
  }, []);

  const handleOutsideClick = useCallback(() => {
    if (!closeOnOutsideClick) return;
    onClose();
  }, []);

   useOutsideClick(handleOutsideClick, modalRef);

  if (!modalContainer) return null;

  return (
    createPortal(
      <ModalStructure
        title={title}
        onClose={onClose}
        children={children}
        modalRef={modalRef}
      />,
      modalContainer
    )
  );
};
