import { useMemo, useState} from 'react';

import {useStore, useTranslation} from '../../../../hooks';
import {Button, Input} from '../../../components/shared';
import {RegexConstants, RoutePaths, T} from '../../../../constants';
import {Icons} from '../../../../enums';
import {useNavigate} from 'react-router-dom';
import Login from '../index';
import {observer} from 'mobx-react-lite';
import {Toaster} from '../../../containers';
import {checkValidations} from '../../../../helpers/form-validation';

interface ForgotPasswordProps {

}

export const ForgotPassword: React.FC<ForgotPasswordProps> = observer(() => {
	const t = useTranslation();
	const navigate = useNavigate()
	const {usersStore} = useStore();

	const [loader, setLoader] = useState(false);
	const [emailValue, setEmailValue] = useState('');
	const [emailSendSuccess, setEmailSendSuccess] = useState(false);

	const handleChange = (value: string) => {
		setEmailValue(value)
	}

	const checkEmailValidation = useMemo(() => {
		return checkValidations(RegexConstants.Email, emailValue)
	}, [emailValue])

	const sendResetPasswordEmail = (event: React.FormEvent) => {
		event.preventDefault();

		if (!checkEmailValidation || loader) return

		setLoader(true)
		usersStore.sendResetPasswordEmail(emailValue).then(() => {
			setEmailSendSuccess(true)
		}).catch(({errorMessage}) => {
			Toaster.error({message: t(errorMessage)});
		}).finally(() => {
			setLoader(false)
		})
	}

	return (
		<Login>
			<form className="sign-in-form" onSubmit={sendResetPasswordEmail}>
				{
					emailSendSuccess ? <>
						<div className="email-sent-success-block">
							<p className="email-sent-success-txt">{t(T.EMAIL_SEND_SUCCESSFULLY)}</p>
							<div className="email-sent-success-icon-block">
								<i className="icon-check-circle"/>
							</div>
						</div>
						<p className="email-request-text">
							{t(T.PLEASE_CHECK_EMAIL)}
						</p>
						<p className="email-request-text"> {t(T.CHECK_EMAIL_INFO)}  </p></> : <>
						<p className="sign-in-form-header">{t(T.FORGOT_PASSWORD)}</p>
						<Input
							label={t(T.EMAIL)}
							className="sign-in-input"
							placeholder={t(T.EMAIL)}
							value={emailValue}
							errorMessage={checkEmailValidation || !emailValue ? '' : t(T.INVALID_EMAIL)}
							onChange={(e) => handleChange(e.target.value)}
						/>
					</>
				}

				<div className="sign-in-btn-wrp">
					{
						!emailSendSuccess ? <Button
							type="submit"
							disabled={(!checkEmailValidation || loader)}
							className="default"
							text={loader ? 'Loading...' : t(T.RESET_PASSWORD)}
						/> : null
					}
					<Button
						type="submit"
						className="default secondary"
						text={t(T.SIGN_IN)}
						preIcon={Icons.ArrowLeft}
						onClick={() => navigate(RoutePaths.Login)}
					/>
				</div>
			</form>
		</Login>
	);
})
