import React, {useState} from 'react';

import {ConfirmAction, Button, Icon, Status, Popover} from '../../../../components/shared';
import {useTranslation} from '../../../../../hooks';
import {Icons} from '../../../../../enums';
import {T} from '../../../../../constants';

interface EditContentHeaderProps {
	name: string;
	icon: Icons;
	lockText: string;
	status?: boolean;
	hasStatus?: boolean;
	unlockText: string;
	isBlocked: boolean;
	lockConfirmText: string;
	unlockConfirmText: string;
	handleLockUnlockClick: (isBlocked: boolean) => void;
	handleResetNewPin?: () => void;
}

export const EditContentHeader: React.FC<EditContentHeaderProps> = ({
																																			icon,
																																			lockText,
																																			name = '',
																																			isBlocked,
																																			unlockText,
																																			lockConfirmText,
																																			unlockConfirmText,
																																			hasStatus = false,
																																			handleLockUnlockClick,
																																			handleResetNewPin,
																																		}) => {

	const t = useTranslation();
	const [open, setOpen] = useState(false);

	return (
		<div className="page-info-content-header">
			<div className="page-info-content-header-left">
				<Icon type={icon} wrap size="md" colorHex="#007fdb"/>
				<div className="name-data-blcok">
					<p className="page-info-player-name">{name || '-'}</p>
				</div>
				{
					hasStatus && isBlocked && (
						<>
							<div className="divider"/>
							<Status
								colorHex={isBlocked ? '#e84855' : '#4caf50'}
								text={t(T.BLOCKED)}
							/>
						</>
					)
				}
			</div>
			<div className="page-info-content-header-right">
				<Popover
					isOpen={open}
					align="right"
					setIsOpen={setOpen}
					className="user-settings-popover"
					opener={
						<Button
							appearance="secondary"
							className="settings-main-btn"
							icon={Icons.ArrowDown}
							text={t(T.SETTINGS)}
						/>
					}
				>
					<ConfirmAction
						onConfirm={() => handleLockUnlockClick(isBlocked)}
						description={t(isBlocked ? unlockConfirmText : lockConfirmText)}
					>
						<div className="settings-btn-wrapper">
							{/*<Button*/}
							{/*    iconSize='sm'*/}
							{/*    type='button'*/}
							{/*    preIcon={Icons.Password}*/}
							{/*    text='Change Password'*/}
							{/*    appearance='light'*/}
							{/*/>*/}
							<Button
								iconSize="sm"
								appearance={isBlocked ? 'success' : 'danger'}
								preIcon={isBlocked ? Icons.Unlock : Icons.Lock}
								text={t(isBlocked ? unlockText : lockText)}
							/>
						</div>
					</ConfirmAction>

					{
						handleResetNewPin ? <ConfirmAction
							onConfirm={handleResetNewPin}
							description={t(T.ARE_YOU_SURE_RESET_PIN)}
						>
							<div className="settings-btn-wrapper">
								<Button
									iconSize="sm"
									appearance="light"
									preIcon={Icons.Key}
									text={t(T.REQUEST_NEW_PIN)}
								/>
							</div>
						</ConfirmAction> : null
					}

				</Popover>
			</div>
		</div>
	);
};
