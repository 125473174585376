import React, { useMemo } from 'react';
import { observer } from 'mobx-react-lite';

import { useStore, useTranslation, useUpdateEffect } from '../../../../../hooks';
import { dataToDropDownConverter } from '../../../../../helpers';
import { DropdownOptionValue } from '../../dropdown/models';
import { T } from '../../../../../constants';
import { Dropdown } from '../../dropdown';

interface BetshopFilterProps {
  value?: DropdownOptionValue;
  onChange?: (value?: DropdownOptionValue) => void;
  setFilters: React.Dispatch<React.SetStateAction<Record<string, any>>>;
}

export const BetshopFilter: React.FC<BetshopFilterProps> = observer(({
  value,
  onChange,
  setFilters
}) => {

  const t = useTranslation();
  const { betshopsStore } = useStore();
  const { betshopsForFilter: { data: betshops } } = betshopsStore;

  useUpdateEffect(() => {
    if (value) {
      const betshop = betshops.find((item) => item.id === +value);
      if (!betshop) {
        setFilters((prev) => {
          const { betshopId, ...restFilters } = prev;
          return restFilters;
        });
      }
    }
  }, [betshops]);
  
  const betshopsData = useMemo(() => {
    return dataToDropDownConverter(betshops, { labelKey: 'name', valueKey: 'id' });
  }, [betshops]);

  return (
    <Dropdown
      hasAll
      value={value}
      onChange={onChange}
      data={betshopsData}
      label={t(T.BETSHOP)}
    />
  );
});
