import {Button, Dropdown, Input, Modal} from '../../../../shared';
import {T} from '../../../../../../constants';
import {PartnerBusiness} from '../../../../../../models';
import {DropdownOptionModel} from '../../../../shared/dropdown/models';
import {useTranslation} from '../../../../../../hooks';
import React from 'react';

interface CreateBusinessModalProps {
	setOpenCreateBusinessModal: (value: boolean) => void
	values: PartnerBusiness,
	countriesList: Array<DropdownOptionModel>,
	handleChange: (value: any, key: string) => void,
	currenciesDropdownData: Array<DropdownOptionModel>,
	handleCreateBusiness: () => void,
	handleCancel: () => void,
	errorMessage: string,
}

const CreateBusinessModal: React.FC<CreateBusinessModalProps> = ({
																																	 setOpenCreateBusinessModal,
																																	 values,
																																	 handleChange,
																																	 currenciesDropdownData,
																																	 handleCreateBusiness,
																																	 handleCancel,
																																	 countriesList,
																																	 errorMessage
																																 }) => {
	const t = useTranslation();

	const disableCreatedBtn = () => {
		const {partnerId, forbidPaymentFromOtherBetshop, isBalanceOperationDisabled, ...requiredValues} = values;
		const data = Object.values(requiredValues);
		return !data.every(item => item)
	}

	return <Modal onClose={() => setOpenCreateBusinessModal(false)} title={t(T.BUSINESS)} closeOnOutsideClick={false}>
		<div className="create-business-form">
			<div className="create-business-form-row">
				<Dropdown
					withSearch
					value={values.countryId || ''}
					className=""
					data={countriesList}
					onChange={(value) => handleChange(value, 'countryId')}
					isDrawSelectedItem={false}
					label={t(T.COUNTRIES)}
				/>
				<Dropdown
					withSearch
					value={values.currencyCode || ''}
					className="header-dropdown"
					data={currenciesDropdownData}
					onChange={(value) => handleChange(value, 'currencyCode')}
					isDrawSelectedItem={false}
					label={t(T.CURRENCY)}
				/>
			</div>
				<Input
					label={''}
					className=""
					placeholder={t(T.BUSINESS_NAME)}
					value={values.name}
					onChange={(e) => handleChange(e.target.value, 'name')}
					errorMessage={errorMessage}
				/>
			<div className="create-business-form-actions">
				<div className="form-btn">
					<Button text={t(T.CANCEL)} onClick={handleCancel} className={'secondary'}/>
				</div>
				<div className="form-btn">
					<Button text={t(T.CREATE)} onClick={handleCreateBusiness} disabled={disableCreatedBtn()}/>
				</div>
			</div>
		</div>
	</Modal>
}

export default CreateBusinessModal