import React, { ChangeEvent, useCallback, useMemo } from 'react';

import { Input } from '../input';

interface NumberInputProps {
  value: string | number;
  label?: string;
  readonly?: boolean;
  disabled?: boolean;
  placeholder?: string;
  numberRegEx?: RegExp;
  errorMessage?: string;
  onChange: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
}

export const NumberInput: React.FC<NumberInputProps> = ({
  value,
  label,
  onChange,
  disabled,
  readonly,
  placeholder,
  numberRegEx,
  errorMessage,
}) => {

  const regExps = useMemo(() => {
    const result = [];
    if (numberRegEx) {
      result.push(numberRegEx);
    }
    return result;
  }, [numberRegEx]);

  const handleChange = useCallback((event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement> ) => {
    const { value } = event.target;
    const isValid = regExps.every((reg) => reg.test(value));
    if (isValid) {
      onChange(event);
    }
  }, [regExps, onChange]);

  return (
    <Input
      label={label}
      value={value}
      readonly={readonly}
      disabled={disabled}
      onChange={handleChange}
      placeholder={placeholder}
      errorMessage={errorMessage}
    />
  );
};
