import React from 'react';

interface NotFoundProps {

}

const NotFound: React.FC<NotFoundProps> = () => {
  return (
    <div>Page not found</div>
  );
};

export default NotFound;
