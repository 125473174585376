import React, {useCallback, useState, useMemo} from 'react';

import {FormElementWithPopover} from '../form-element-with-popover';
import {DropdownOptionModel, DropdownOptionValue} from './models';
import {DropdownContent} from './dropdown-content';
import {Icons} from '../../../../enums';

import './style.scss';
import {useTranslation} from '../../../../hooks';

const DROPDOWN_ALL_VALUE = 'DROPDOWN_ALL_VALUE';
const DROPDOWN_ALL_LABEL = 'ALL';

interface DropdownProps {
	label?: string;
	replacementValue?: string;
	error?: boolean;
	hasAll?: boolean;
	disabled?: boolean;
	readonly?: boolean;
	size?: 'sm' | 'lg';
	className?: string;
	withSearch?: boolean;
	isDrawSelectedItem?: boolean;
	value?: DropdownOptionValue;
	data: Array<DropdownOptionModel>;
	direction?: 'to-top' | 'to-bottom';
	onChange?: (value?: DropdownOptionValue) => void;
}

export const Dropdown: React.FC<DropdownProps> = ({
																										size,
																										data,
																										label,
																										error,
																										value,
																										hasAll,
																										readonly,
																										disabled,
																										onChange,
																										withSearch,
																										className = '',
																										direction = 'to-bottom',
																										isDrawSelectedItem = true,
																										replacementValue
																									}) => {
	const t = useTranslation()
	const [open, setOpen] = useState(false);

	const handleChange = useCallback((data: Array<DropdownOptionValue>) => {
		setOpen(false);
		const [valueOrAll] = data;
		if (`${valueOrAll}` === `${value}`) return;
		if (onChange) {
			const value = valueOrAll === DROPDOWN_ALL_VALUE ? undefined : valueOrAll;
			onChange(value);
		}
	}, [onChange, value]);

	const selectedValueLabel = useMemo(() => {
		const selectedItem = data.find((item) => `${item.value}` === `${value}`);
		return selectedItem?.label  || (hasAll ? DROPDOWN_ALL_LABEL : '');
	}, [data, value, hasAll]);

	const dropdownData = useMemo(() => {
		return hasAll ? [{value: DROPDOWN_ALL_VALUE, label: DROPDOWN_ALL_LABEL}, ...data] : data;
	}, [data, hasAll]);

	const dropdownValue = useMemo(() => {
		return value ? [value] : [DROPDOWN_ALL_VALUE];
	}, [value]);

	// console.log({selectedValueLabel, replacementValue}, 'selectedValueLabel')
	return (
		<FormElementWithPopover
			open={open}
			size={size}
			error={error}
			label={label}
			setOpen={setOpen}
			readonly={readonly}
			disabled={disabled}
			className={className}
			icon={Icons.ArrowDown}
			value={!selectedValueLabel && replacementValue ? replacementValue : typeof selectedValueLabel === 'string' ? t(selectedValueLabel) : selectedValueLabel}
		>
			<DropdownContent
				data={isDrawSelectedItem ? dropdownData : dropdownData.filter(item => item.value !== value)}
				className={direction}
				onChange={handleChange}
				withSearch={withSearch}
				selectedItems={dropdownValue}
			/>
		</FormElementWithPopover>
	);
};
