import React, {ChangeEvent, useRef} from 'react';

import {guid} from '../../../../helpers';
import {Icons} from '../../../../enums';
import {Icon} from '../icon';

import './style.scss';
import {useTranslation} from '../../../../hooks';
import {T} from '../../../../constants';

type InputType = 'text' | 'password';

interface InputProps {
	icon?: Icons | null;
	value: string | number;
	name?: string;
	label?: string;
	type?: InputType;
	disabled?: boolean;
	className?: string;
	readonly?: boolean;
	required?: boolean;
	dontHighlightError?: boolean;
	textarea?: boolean;
	placeholder?: string;
	errorMessage?: string;
	autoComplete?: string;
	onIconClick?: () => void;
	onKeyPress?: (e: React.KeyboardEvent) => void;
	onChange: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
}

export const Input: React.FC<InputProps> = ({
																							icon,
																							name,
																							onChange,
																							disabled,
																							readonly,
																							required,
																							value = '',
																							label = '',
																							onIconClick,
																							type = 'text',
																							className = '',
																							placeholder = '',
																							textarea = false,
																							errorMessage = '',
																							autoComplete = 'off',
																							onKeyPress,
																							dontHighlightError
																						}) => {
	const t = useTranslation()
	const inputId = useRef(guid());

	return (
		<div className={`input-holder ${errorMessage ? 'error' : ''} ${className}`}>
			<div className="input-actions">
				{
					icon ? (
						<>
							<Icon
								type={icon}
								onClick={onIconClick}
								className="input-action"
							/>
							{/*<i className="icon-warning waring-icon"/>*/}
							{/*<span className="verified-txt">Email is not Verified</span>*/}
						</>
					) : null
				}
			</div>

			{/*  Tooltip end*/}
			<div className={`input-item ${errorMessage && !dontHighlightError ? 'error' : ''}`}>
				{
					textarea ? (
						<textarea
							name={name}
							value={value}
							onChange={onChange}
							className="textarea"
							readOnly={readonly}
							id={inputId.current}
							placeholder={t(T.COMMENT_AREA)}
						/>
					) : (
						<input
							name={name}
							type={type}
							value={value}
							className="input"
							onChange={onChange}
							readOnly={readonly}
							disabled={disabled}
							id={inputId.current}
							placeholder={placeholder}
							autoComplete={autoComplete}
							onKeyPress={onKeyPress}
						/>
					)
				}
				<label
					htmlFor={inputId.current}
					className={`input-label ${required ? 'required' : ''}`}
				>
					<span className="label-txt">{label}</span>
				</label>
				{
					errorMessage ? (
						<span
							className="input-error-message"
							title={errorMessage}
						>
              {t(errorMessage)}
            </span>
					) : null
				}
			</div>
		</div>
	);
};
