import { useMemo } from 'react';
import { observer } from 'mobx-react-lite';

import { dataToDropDownConverter } from '../../../../../helpers';
import { useStore, useTranslation } from '../../../../../hooks';
import { DropdownOptionValue } from '../../dropdown/models';
import { T } from '../../../../../constants';
import { Dropdown } from '../../dropdown';
import {toJS} from 'mobx';

interface PartnerFilterProps {
  value?: DropdownOptionValue;
  onChange?: (value?: DropdownOptionValue) => void;
}

export const PartnerFilter: React.FC<PartnerFilterProps> = observer(({
  value,
  onChange
}) => {

  const t = useTranslation();
  const { partnerStore } = useStore();

  const { partnersForFilter: { data: partners } } = partnerStore;

  const partnersData = useMemo(() => {
    return dataToDropDownConverter(partners, { labelKey: 'name', valueKey: 'id' });
  }, [partners]);

  return (
    <Dropdown
      hasAll
      withSearch
      value={value}
      onChange={onChange}
      data={partnersData}
      label={t(T.PARTNER)}
    />
  );
});
