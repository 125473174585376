import { getCurrentLanguageCode } from '../helpers';
import { rootStore } from '../store';
import { UserType } from '../enums';

class AppService {

  public async init(): Promise<void> {
    const { translationsStore, walletStore, currenciesStore, userSettingsStore, partnerStore, businessStore } = rootStore;
    const [ user, languages ] = await Promise.all([
      userSettingsStore.getUserSettings().then((result) => {
        if (result.userType === UserType.Manager && result.businessUnitId) {
          businessStore.getBusinessById(result.businessUnitId)
        }
        return result
      }),
      translationsStore.getLanguages(),
      currenciesStore.getCurrencies()
    ]);
    const defaultLan = languages.find((lan) => lan.isDefault);
    const calls = [ translationsStore.getTranslations({ code: getCurrentLanguageCode(defaultLan?.uid) }) ];
    if (user.userType !== UserType.SuperAdmin && user.userType !== UserType.Admin) {
      calls.push(walletStore.getWallet(user.id));
    }

    if (user.userType === UserType.Partner) {
      calls.push(partnerStore.getPartnerBusiness());
    }

    await Promise.all(calls);
  }

}

export const appService = new AppService();
