import React, { useMemo } from 'react';
import { observer } from 'mobx-react-lite';

import { useStore, useTranslation, useUpdateEffect } from '../../../../../hooks';
import { dataToDropDownConverter } from '../../../../../helpers';
import { DropdownOptionValue } from '../../dropdown/models';
import { T } from '../../../../../constants';
import { Dropdown } from '../../dropdown';

interface ManagerFilterProps {
  value?: DropdownOptionValue;
  onChange?: (value?: DropdownOptionValue) => void;
  setFilters: React.Dispatch<React.SetStateAction<Record<string, any>>>;
}

export const ManagerFilter: React.FC<ManagerFilterProps> = observer(({
  value,
  onChange,
  setFilters
}) => {

  const t = useTranslation();
  const { usersStore } = useStore();
  const { managersForFilter: { data: managers } } = usersStore;

  useUpdateEffect(() => {
    if (value) {
      const manager = managers.find((item) => item.id === +value);
      if (!manager) {

        setFilters((prev) => {
          const { managerId, ...restFilters } = prev;
          return restFilters;
        });
      }
    }
  }, [managers]);
  
  const managersData = useMemo(() => {
    return dataToDropDownConverter(managers, { labelKey: 'userName', valueKey: 'id' });
  }, [managers]);

  return (
    <Dropdown
      hasAll
      value={value}
      onChange={onChange}
      data={managersData}
      label={t(T.MANAGER)}
    />
  );
});
