import React, {useRef, useCallback} from 'react';

import {DropdownOptionValue} from '../dropdown/models';
import {useOutsideClick} from '../../../../hooks';
import {Icons} from '../../../../enums';
import {Icon} from '../icon';

interface FormElementWithPopoverProps {
	icon: Icons;
	open: boolean;
	label?: string;
	error?: boolean;
	disabled?: boolean;
	readonly?: boolean;
	size?: 'sm' | 'lg';
	className?: string;
	children: React.ReactNode;
	value: DropdownOptionValue | null;
	setOpen: (value: boolean | ((value: boolean) => boolean)) => void;
}

export const FormElementWithPopover: React.FC<FormElementWithPopoverProps> = ({
																																								open,
																																								icon,
																																								value,
																																								error,
																																								label,
																																								setOpen,
																																								readonly,
																																								disabled,
																																								children,
																																								size = 'lg',
																																								className = ''
																																							}) => {

	const openerRef = useRef<HTMLDivElement | null>(null);
	const popoverRef = useRef<HTMLDivElement | null>(null);

	const onClose = useCallback(() => {
		setOpen(false);
	}, [setOpen]);

	const toggle = useCallback(() => {
		if (readonly) return;
		setOpen(prev => !prev);
	}, [readonly, setOpen]);

	useOutsideClick(onClose, popoverRef, openerRef);

	return (
		<div className={`dropdown ${className}`}>
			<div
				ref={openerRef}
				onClick={toggle}
				className={`
          dropdown-toggle ${size} ${open ? 'selected' : ''} ${readonly ? 'readonly' : ''} 
          ${disabled ? 'disabled' : ''}  ${error ? 'error' : ''}`
				}
			>
				<p className="dropdown-txt">{value ?? label}</p>
				<Icon
					size="xs"
					type={icon}
					className="dropdown-icon"
				/>
				<div className="dropdown-label">{label}</div>
			</div>
			<div ref={popoverRef}>
				{open ? children : null}
			</div>
		</div>
	);
};
