import React from 'react';

import { Icons } from '../../../../enums';
import { Icon, IconSize } from '../icon';

import './style.scss';

type ButtonSize = 'sm' | 'md' | 'default';
type ButtonType = 'submit' | 'reset' | 'button';
type ButtonAppearance = 'primary' | 'secondary' | 'secondary-outline' | 'light' | 'light-secondary' | 'danger' | 'success';

interface ButtonProps {
  icon?: Icons | null;
  text?: string;
  preIcon?: Icons;
  size?: ButtonSize;
  type?: ButtonType;
  disabled?: boolean;
  className?: string;
  iconSize?: IconSize;
  appearance?: ButtonAppearance;
  onClick?: (event: React.MouseEvent) => void;
}

export const Button: React.FC<ButtonProps> = ({
  icon,
  text,
  preIcon,
  onClick,
  disabled,
  className = '',
  iconSize = 'xs',
  type = 'button',
  size = 'default',
  appearance = 'primary'
}) => {
  return (
    <button
      type={type}
      onClick={onClick}
      className={`button ${size} ${appearance} ${className} ${disabled ? 'btn-disabled' : ''}`}
    >
      { preIcon ? <Icon type={preIcon} size={iconSize} /> : null }
      {
        text ? (
          <span className="button-txt">{ text }</span>
        ) : null
      }
      { icon ? <Icon type={icon} size={iconSize} /> : null }
    </button>
  );
};
