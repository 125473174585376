import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { observer } from "mobx-react-lite";

import {
  AddEditViewWrapper,
  AddEditViewHeader,
  AddEditViewContent,
  AddEditViewFooter,
  Breadcrumb,
  DialogModal,
  Loader,
  EditContentHeader,
} from "../../../components/shared";
import { BreadcrumbItemData } from "../../../components/shared/breadcrumb/models";
import { useStore, useTranslation, useForm } from "../../../../hooks";
import { dataToDropDownConverter } from "../../../../helpers";
import { RoutePaths, T } from "../../../../constants";
import { Icons, UserType } from "../../../../enums";
import { AddUserData } from "../../../../models";
import { Toaster } from "../../../containers";
import { toJS } from "mobx";
import { AddEditViewCashierForm } from "../../cashiers/add-edit-cashier/add-edit-cashier-form";
import { EditGameControlForm } from "./edit-game-control-form";

interface CreateCashierProps {}

const EditGamesControl: React.FC<CreateCashierProps> = observer(() => {
  const t = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const {
    partnerStore,
    usersStore,
    betshopsStore,
    userSettingsStore,
    walletStore,
  } = useStore();
  const {
    userById: { isLoading: isEditLoading, data: userById },
  } = usersStore;
  const {
    isAdmin,
    userSettings: { data: userSettings },
  } = userSettingsStore;
  const {
    betshopsForFilter: { data: betshopsForFilter },
  } = betshopsStore;
  const {
    partnersForFilter: { data: partnersForFilter },
    selectedBusiness,
    partnerBusiness: { data },
  } = partnerStore;
  const {
    id: userId,
    userName,
    firstName = "",
    lastName = "",
    isBlocked = false,
  } = userById;
  const [isDialogModalOpen, setIsDialogModalOpen] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const { userType, businessUnitId: businessUnitIdUser } = userSettings;
  const {
    values,
    setValues,
    resetForm,
    handleInputChange,
    handleDropdownChange,
  } = useForm({
    email: "",
    comment: "",
    lastName: "",
    userName: "",
    password: "",
    firstName: "",
    betshopId: "",
    partnerId: null,
    userType: UserType.Cashier,
    businessUnitId: null,
  });
  const editable = !!id;
  const viewMode = editable && !editMode;

  useEffect(() => {
    getUserById();
  }, []);

  const getUserById = useCallback(async () => {
    if (editable) {
      return await usersStore.getUserById(+id);
    }
  }, [editable, id]);

  const navigateToCashiersList = useCallback(
    () => navigate(RoutePaths.Cashiers),
    [],
  );

  const breadcrumbData: Array<BreadcrumbItemData> = useMemo(() => {
    return [
      {
        name: t(T.CASHIERS),
        onClick: navigateToCashiersList,
      },
      {
        active: true,
        name: editable ? userName || "" : t(T.CREATE_CASHIER),
      },
    ];
  }, [userName, navigateToCashiersList, editable, t]);

  return (
    <AddEditViewWrapper>
      <AddEditViewHeader>
        <Breadcrumb items={breadcrumbData} />
      </AddEditViewHeader>
      {isEditLoading && editable ? (
        <Loader className="add-edit-view-loader" />
      ) : (
        <>
          <AddEditViewContent>
            <EditGameControlForm />
          </AddEditViewContent>
          {
            // TODO: temporarily conditional render
            <AddEditViewFooter
              addMode={editable}
              editMode={editMode}
              setEditMode={setEditMode}
              createDisabled={false}
              onCancel={navigateToCashiersList}
              onSave={() => setIsDialogModalOpen(true)}
            />
          }
          {isDialogModalOpen && (
            <DialogModal
              iconColorHex="#007fdb"
              onConfirm={() => {}}
              confirmText={t(T.SAVE)}
              icon={Icons.InfoCircle}
              cancelText={t(T.DISCARD)}
              onClose={() => setIsDialogModalOpen(false)}
              description={t(T.ARE_YOU_SURE_CREATE_CASHIER)}
            />
          )}
        </>
      )}
    </AddEditViewWrapper>
  );
});

export default EditGamesControl;
