import React, {useMemo} from 'react';
import {observer} from 'mobx-react-lite';

import {dataToDropDownConverter} from '../../../../../helpers';
import {useStore, useTranslation} from '../../../../../hooks';
import {DropdownOptionValue} from '../../dropdown/models';
import {T} from '../../../../../constants';
import {Dropdown} from '../../dropdown';

interface BusinessFilterProps {
	value?: DropdownOptionValue;
	partnerId: number;
	onChange?: (value?: DropdownOptionValue) => void;
}

export const BusinessFilter: React.FC<BusinessFilterProps> = observer(({
																																				 value,
																																				 onChange,
																																				 partnerId
																																			 }) => {

	const t = useTranslation();
	const {partnerStore} = useStore();

	const {partnerBusiness: {data}} = partnerStore;

	const businessData = useMemo(() => {
		return data ? dataToDropDownConverter(data.items, {labelKey: 'name', valueKey: 'id'}) : [];
	}, [data, partnerId]);

	return (
		<Dropdown
			hasAll
			value={value}
			onChange={onChange}
			data={businessData}
			label={t(T.BUSINESS)}
		/>
	);
});
