import React, {ChangeEvent, useCallback, useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';

import {useDebounce, useFilters, useQuery, useTranslation} from '../../../../hooks';
import {combineQuery} from '../../../../helpers';
import {T} from '../../../../constants';
import {Icons} from '../../../../enums';
import {Input} from '../input';

interface TableSearchProps {
	searchProp: string;
	placeholderName?: string;
	validate?: boolean;
	regex?: RegExp;
}

export const TableSearch: React.FC<TableSearchProps> = ({
																													searchProp,
																													placeholderName,
																													validate,
																													regex,
																												}) => {

	const t = useTranslation();
	const {query} = useQuery()
	const navigate = useNavigate();
	const {filters, handleInputChange} = useFilters();
	const [searchTerm, setSearchTerm] = useState('');
	const debouncedSearchTerm = useDebounce<string>(searchTerm, 500);

	useEffect(() => {
		navigate(combineQuery({
			...query,
			...filters
		}))
	}, [debouncedSearchTerm]);

	const handleSearch = useCallback((e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, searchProp: string) => {
		const { value } = e.target;
		const isValid = validate ? (regex?.test(value) || value === '') : true;

		if (isValid) {
			handleInputChange(e, searchProp);
			setSearchTerm(value);
		}
	}, []);

	return (
		<Input
			label={t(T.SEARCH)}
			icon={Icons.Search}
			placeholder={placeholderName || t(T.SEARCH)}
			value={filters[searchProp]}
			onChange={(e) => handleSearch(e, searchProp)}
		/>
	);
};
