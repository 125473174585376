import { memo, useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { observer } from "mobx-react-lite";

import { Button, Table, TableSearch } from "../../../components/shared";
import {
  useQuery,
  useTranslation,
  useTable,
  useStore,
} from "../../../../hooks";
import { User } from "../../../../models";
import { TableColumn } from "../../../components/shared/table/models";
import { Icons, TableColumnType } from "../../../../enums";
import { DEFAULT_TAKE, T } from "../../../../constants";
import { calcTablePage } from "../../../../helpers";
import { toJS } from "mobx";

interface GamesControlListProps {}

const GamesControlList: React.FC<GamesControlListProps> = observer(() => {
  const t = useTranslation();
  const navigate = useNavigate();
  const { query, queryString } = useQuery();
  const { onPaginationChange } = useTable();

  const skip = +query.skip || 0;
  const take = +query.take || DEFAULT_TAKE;
  const [page, setPage] = useState(calcTablePage(skip, take));

  // const getUsers = useCallback(() => {
  //   setPage(calcTablePage(skip, take));
  //
  //   usersStore.getUsers({
  //     userType: UserType.Cashier,
  //     ...query,
  //     skip,
  //     take,
  //     businessUnitId: selectedBusiness?.id || query.businessUnitId,
  //   });
  // }, [query, skip, take]);

  const columns = useMemo<Array<TableColumn>>(
    () => [
      {
        title: t(T.CASHIER),
        dataKey: "userName",
        type: TableColumnType.Text,
      },
      {
        title: t(T.PARTNERS),
        dataKey: "partnerName",
        type: TableColumnType.Text,
      },
      {
        title: t(T.MANAGERS),
        dataKey: "managerName",
        type: TableColumnType.Text,
      },
      {
        title: t(T.ADDRESS),
        dataKey: "address",
        type: TableColumnType.Text,
      },
    ],
    [],
  );

  const rowActions = useCallback(
    (row: User) => [
      {
        icon: Icons.Edit,
        onClick: () => navigate(`${12559}/edit`),
      },
    ],
    [],
  );

  const data = [
    {
      userName: "cashierrub",
      partnerName: "GravityPartner",
      managerName: "",
      address: ")))))))))))))))))))))))))",
    },
    {
      userName: "cashierrub",
      partnerName: "GravityPartner",
      managerName: "",
      address: ")))))))))))))))))))))))))",
    },
    {
      userName: "cashierrub",
      partnerName: "GravityPartner",
      managerName: "",
      address: ")))))))))))))))))))))))))",
    },
    {
      userName: "cashierrub",
      partnerName: "GravityPartner",
      managerName: "",
      address: ")))))))))))))))))))))))))",
    },
  ];

  return (
    <>
      <Table
        page={page}
        rows={data}
        rowsCount={5}
        columns={columns}
        loading={false}
        selectorValue={take}
        title={t(T.CASHIERS)}
        onRefreshClick={() => {}}
        getRowActions={rowActions}
        onPaginationChange={onPaginationChange}
        leftActions={<TableSearch searchProp="userName" />}
      />
    </>
  );
});

export default memo(GamesControlList);
