const TRANSLATION_KEYS = {
  JANUARY: "JANUARY",
  FEBRUARY: "FEBRUARY",
  MARCH: "MARCH",
  APRIL: "APRIL",
  MAY: "MAY",
  JUNE: "JUNE",
  JULY: "JULY",
  AUGUST: "AUGUST",
  SEPTEMBER: "SEPTEMBER",
  SETTINGS: "SETTINGS",
  OCTOBER: "OCTOBER",
  NOVEMBER: "NOVEMBER",
  DECEMBER: "DECEMBER",
  DATE: "DATE",
  WELCOME: "WELCOME",
  DEPOSIT: "DEPOSIT",
  WITHDRAW: "WITHDRAW",
  MONDAY_SHORT: "MONDAY_SHORT",
  NO_DATA_TO_DISPLAY: "NO_DATA_TO_DISPLAY",
  ALL: "ALL",
  PIN: "PIN",
  TUESDAY_SHORT: "TUESDAY_SHORT",
  WEDNESDAY_SHORT: "WEDNESDAY_SHORT",
  REQUEST_NEW_PIN: "REQUEST_NEW_PIN",
  TICKET_IS_PAID: "TICKET_IS_PAID",
  THURSDAY_SHORT: "THURSDAY_SHORT",
  FRIDAY_SHORT: "FRIDAY_SHORT",
  SATURDAY_SHORT: "SATURDAY_SHORT",
  SUNDAY_SHORT: "SUNDAY_SHORT",
  START_DATE: "START_DATE",
  END_DATE: "END_DATE",
  SOMETHING_WENT_WRONG: "SOMETHING_WENT_WRONG",
  WELCOME_TO_PASCAL_GRAVITY_BACKOFFICE: "WELCOME_TO_PASCAL_GRAVITY_BACKOFFICE",
  LOSE: "LOSE",
  OF: "OF",
  WIN: "WIN",
  PENDING: "PENDING",
  CANCELED: "CANCELED",
  UNKNOWN: "UNKNOWN",
  CALCULATION: "CALCULATION",
  ON_CANCEL: "ON_CANCEL",
  WON: "WON",
  LOST: "LOST",
  RETURNED: "RETURNED",
  DASHBOARD: "DASHBOARD",
  BET_HISTORY: "BET_HISTORY",
  PARTNERS: "PARTNERS",
  BETSHOPS: "BETSHOPS",
  MANAGERS: "MANAGERS",
  CASHIERS: "CASHIERS",
  TRANSACTION_HISTORY: "TRANSACTION_HISTORY",
  CANCEL: "CANCEL",
  CONFIGURATIONS: "CONFIGURATIONS",
  CONFIRM: "CONFIRM",
  APPLY: "APPLY",
  RESET: "RESET",
  // WELCOME: 'WELCOME',
  WELCOME_TO_GRAVITY: "WELCOME_TO_GRAVITY",
  SIGN_IN_TITLE: "SIGN_IN_TITLE",
  SIGN_IN: "SIGN_IN",
  USERNAME: "USERNAME",
  PASSWORD: "PASSWORD",
  REVENUE: "REVENUE",
  GGR: "GGR",
  GGR_RATIO: "GGR_RATIO",
  TOTAL_BET_AMOUNT: "TOTAL_BET_AMOUNT",
  WON_BET_AMOUNT: "WON_BET_AMOUNT",
  TOTAL_WIN_AMOUNT: "TOTAL_WIN_AMOUNT",
  LOST_BET_AMOUNT: "LOST_BET_AMOUNT",
  RETURNED_BET_AMOUNT: "RETURNED_BET_AMOUNT",
  PENDING_BET_AMOUNT: "PENDING_BET_AMOUNT",
  TOTAL_BET_COUNT: "TOTAL_BET_COUNT",
  WON_BET_COUNT: "WON_BET_COUNT",
  RETURNED_BET_COUNT: "RETURNED_BET_COUNT",
  LOST_BET_COUNT: "LOST_BET_COUNT",
  PENDING_BET_COUNT: "PENDING_BET_COUNT",
  PARTNER: "PARTNER",
  MANAGER: "MANAGER",
  CASHIER: "CASHIER",
  BETSHOP: "BETSHOP",
  LIMIT: "LIMIT",
  LAST_ACTIVITY: "LAST_ACTIVITY",
  CREATE: "CREATE",
  CREATE_CASHIER: "CREATE_CASHIER",
  SEARCH: "SEARCH",
  BALANCE: "BALANCE",
  UPDATE_BALANCE: "UPDATE_BALANCE",
  EDIT_BALANCE: "EDIT_BALANCE",
  EDIT_LIMIT: "EDIT_LIMIT",
  CURRENT_BALANCE: "CURRENT_BALANCE",
  CURRENT_LIMIT: "CURRENT_LIMIT",
  NEW_BALANCE: "NEW_BALANCE",
  NEW_LIMIT: "NEW_LIMIT",
  UPDATE_LIMIT: "UPDATE_LIMIT",
  BALANCE_UPDATED_SUCCESSFULLY: "UPDATE_BALANCE_SUCCESSFULLY",
  BETSHOP_UPDATE_SUCCESSFULLY: "BETSHOP_UPDATE_SUCCESSFULLY",
  LIMIT_UPDATED_SUCCESSFULLY: "LIMIT_BALANCE_SUCCESSFULLY",
  CHANGE_LIMIT_TO: "CHANGE_LIMIT_TO",
  ARE_YOU_SURE_UPDATE_BALANCE: "ARE_YOU_SURE_UPDATE_BALANCE",
  ARE_YOU_SURE_UPDATE_LIMIT: "ARE_YOU_SURE_UPDATE_LIMIT",
  UPDATE_LIMIT_DESCRIPTION: "UPDATE_LIMIT_DESCRIPTION",
  INSUFFICIENT_LIMIT: "INSUFFICIENT_LIMIT",
  INSUFFICIENT_BALANCE: "INSUFFICIENT_BALANCE",
  MAIN: "MAIN",
  FINANCIAL: "FINANCIAL",
  COMMENT: "COMMENT",
  SIGN_OUT: "SIGN_OUT",
  LANGUAGES: "LANGUAGES",
  EMAIL: "EMAIL",
  FIRST_NAME: "FIRST_NAME",
  LAST_NAME: "LAST_NAME",
  CURRENCY: "CURRENCY",
  ARE_YOU_SURE_CREATE_CASHIER: "ARE_YOU_SURE_CREATE_CASHIER",
  DISCARD: "DISCARD",
  SAVE: "SAVE",
  CASHIER_CREATED_SUCCESSFULLY: "CASHIER_CREATED_SUCCESSFULLY",
  EDIT: "EDIT",
  LOCK_USER: "LOCK_USER",
  UNLOCK_USER: "UNLOCK_USER",
  ARE_YOU_SURE_TO_LOCK_CASHIER: "ARE_YOU_SURE_TO_LOCK_CASHIER",
  ARE_YOU_SURE_TO_UNLOCK_CASHIER: "ARE_YOU_SURE_TO_UNLOCK_CASHIER",
  CASHIER_LOCKED_SUCCESSFULLY: "CASHIER_LOCKED_SUCCESSFULLY",
  CASHIER_UNLOCKED_SUCCESSFULLY: "CASHIER_UNLOCKED_SUCCESSFULLY",
  BARCODE: "BARCODE",
  BET_AMOUNT: "BET_AMOUNT",
  WIN_AMOUNT: "WIN_AMOUNT",
  BET_DATE: "BET_DATE",
  PAYMENT_DATE: "PAYMENT_DATE",
  PRINTED: "PRINTED",
  STATUS: "STATUS",
  TICKET_DETAILS: "TICKET_DETAILS",
  DATE_AND_TIME: "DATE_AND_TIME",
  TICKET_STAKE: "TICKET_STAKE",
  STAKE: "STAKE",
  TOTAL_ODD: "TOTAL_ODD",
  ID: "ID",
  CREATE_PARTNER: "CREATE_PARTNER",
  FULL_NAME: "FULL_NAME",
  PARTNER_CREATED_SUCCESSFULLY: "PARTNER_CREATED_SUCCESSFULLY",
  ARE_YOU_SURE_TO_LOCK_PARTNER: "ARE_YOU_SURE_TO_LOCK_PARTNER",
  ARE_YOU_SURE_TO_UNLOCK_PARTNER: "ARE_YOU_SURE_TO_UNLOCK_PARTNER",
  PARTNER_LOCKED_SUCCESSFULLY: "PARTNER_LOCKED_SUCCESSFULLY",
  PARTNER_UNLOCKED_SUCCESSFULLY: "PARTNER_UNLOCKED_SUCCESSFULLY",
  ARE_YOU_SURE_EDIT_BETSHOP: "ARE_YOU_SURE_EDIT_BETSHOP",
  NAME: "NAME",
  ARE_YOU_SURE_CREATE_PARTNER: "ARE_YOU_SURE_CREATE_PARTNER",
  LOCK_PARTNER: "LOCK_PARTNER",
  UNLOCK_PARTNER: "UNLOCK_PARTNER",
  CREATE_BETSHOP: "CREATE_BETSHOP",
  ADDRESS: "ADDRESS",
  ACTIVE: "ACTIVE",
  BLOCKED: "BLOCKED",
  ONLINE: "ONLINE",
  OFFLINE: "OFFLINE",
  BETSHOP_CREATED_SUCCESSFULLY: "BETSHOP_CREATED_SUCCESSFULLY",
  BETSHOP_UNLOCKED_SUCCESSFULLY: "BETSHOP_UNLOCKED_SUCCESSFULLY",
  BETSHOP_LOCKED_SUCCESSFULLY: "BETSHOP_LOCKED_SUCCESSFULLY",
  ARE_YOU_SURE_TO_LOCK_BETSHOP: "ARE_YOU_SURE_TO_LOCK_BETSHOP",
  ARE_YOU_SURE_TO_UNLOCK_BETSHOP: "ARE_YOU_SURE_TO_UNLOCK_BETSHOP",
  LOCK_BETSHOP: "LOCK_BETSHOP",
  UNLOCK_BETSHOP: "UNLOCK_BETSHOP",
  ARE_YOU_SURE_CREATE_BETSHOP: "ARE_YOU_SURE_CREATE_BETSHOP",
  BETSHOP_NAME: "BETSHOP_NAME",
  INACTIVE: "INACTIVE",
  CREATE_MANAGER: "CREATE_MANAGER",
  MANAGER_CREATED_SUCCESSFULLY: "MANAGER_CREATED_SUCCESSFULLY",
  ARE_YOU_SURE_CREATE_MANAGER: "ARE_YOU_SURE_CREATE_MANAGER",
  LOCK_MANAGER: "LOCK_MANAGER",
  UNLOCK_MANAGER: "UNLOCK_MANAGER",
  ARE_YOU_SURE_TO_LOCK_MANAGER: "ARE_YOU_SURE_TO_LOCK_MANAGER",
  ARE_YOU_SURE_TO_UNLOCK_MANAGER: "ARE_YOU_SURE_TO_UNLOCK_MANAGER",
  MANAGER_UNLOCKED_SUCCESSFULLY: "MANAGER_UNLOCKED_SUCCESSFULLY",
  PIN_CODE_SUCCESS: "PIN_CODE_SUCCESS",
  MANAGER_LOCKED_SUCCESSFULLY: "MANAGER_LOCKED_SUCCESSFULLY",
  SINGLE: "SINGLE",
  EXPRESS: "EXPRESS",
  BET_TYPE: "BET_TYPE",
  GAME_NAME: "GAME_NAME",
  FILTERS: "FILTERS",
  PAID: "PAID",
  ARE_YOU_SURE_RESET_PIN: "ARE_YOU_SURE_RESET_PIN",
  COUNTRIES: "COUNTRIES",
  BETSHOP_ALREADY_EXISTS: "BETSHOP_ALREADY_EXISTS",
  LENGTH_VALIDATION: "LENGTH_VALIDATION",
  TICKET_CONFIGURATIONS: "TICKET_CONFIGURATIONS",
  PARTNER_NAME: "PARTNER_NAME",
  COUNTRY: "COUNTRY",
  AMOUNT: "AMOUNT",
  PERCENTAGE: "PERCENTAGE",
  PAYOUT_FROM_DIFFERENT_BET_SHOPS: "PAYOUT_FROM_DIFFERENT_BET_SHOPS",
  REQUIRED_PIN_FOR_PAYOUTS: "REQUIRED_PIN_FOR_PAYOUTS",
  WITH_OR_WITHOUT_PIN: "WITH_OR_WITHOUT_PIN",
  PIN_CODE: "PIN_CODE",

  // BUSINESS TRANSLATIONS
  BUSINESS_SETTINGS: "BUSINESS_SETTINGS",
  DO_NOT_SHOW_BALANCE_FOR_THE_BUSINESS: "DO_NOT_SHOW_BALANCE_FOR_THE_BUSINESS",
  BUSINESS_NAME: "BUSINESS_NAME",
  BUSINESS_ID: "BUSINESS_ID",
  BUSINESS: "BUSINESS",
  BUSINESSES: "BUSINESSES",
  CREATE_BUSINESS: "CREATE_BUSINESS",
  BUSINESS_BALANCE: "BUSINESS_BALANCE",
  BUSINESS_LIMIT: "BUSINESS_LIMIT",
  BUSINESS_BETSHOP_NAME_VALIDATION: "BUSINESS_BETSHOP_NAME_VALIDATION",
  BUSINESS_UNIT_ALREADY_EXISTS: "BUSINESS_UNIT_ALREADY_EXISTS",
  CHOOSE_BUSINESS: "CHOOSE_BUSINESS",
  BUSINESS_CREATED_SUCCESSFULLY: "BUSINESS_CREATED_SUCCESSFULLY",
  LOCK_BUSINESS: "LOCK_BUSINESS",
  UNLOCK_BUSINESS: "UNLOCK_BUSINESS",
  ARE_YOU_SURE_TO_LOCK_BUSINESS: "ARE_YOU_SURE_TO_LOCK_BUSINESS",
  ARE_YOU_SURE_TO_UNLOCK_BUSINESS: "ARE_YOU_SURE_TO_UNLOCK_BUSINESS",
  ARE_YOU_SURE_EDIT_BUSINESS: "ARE_YOU_SURE_EDIT_BUSINESS",
  ARE_YOU_SURE_CREATE_BUSINESS: "ARE_YOU_SURE_CREATE_BUSINESS",
  BUSINESS_UPDATE_SUCCESSFULLY: "BUSINESS_UPDATE_SUCCESSFULLY",
  BUSINESS_UNLOCKED_SUCCESSFULLY: "BUSINESS_UNLOCKED_SUCCESSFULLY",
  BUSINESS_LOCKED_SUCCESSFULLY: "BUSINESS_LOCKED_SUCCESSFULLY",
  YOUR_COMMENT_HERE: "YOUR_COMMENT_HERE",
  TICKET_ID: "TICKET_ID",
  GAME: "GAME",
  ROUND_ID: "ROUND_ID",
  POSSIBLE_WIN: "POSSIBLE_WIN",
  MARKET_NAME: "MARKET_NAME",
  UPDATE: "UPDATE",
  ONE_NUMBER: "ONE_NUMBER",
  INVALID_EMAIL: "INVALID_EMAIL",
  RESET_PASSWORD: "RESET_PASSWORD",
  FORGOT_PASSWORD: "FORGOT_PASSWORD",
  PASSWORD_CHANGED: "PASSWORD_CHANGED",
  SET_NEW_PASSWORD: "SET_NEW_PASSWORD",
  CHECK_EMAIL_INFO: "CHECK_EMAIL_INFO",
  PLEASE_CHECK_EMAIL: "PLEASE_CHECK_EMAIL",
  AT_LEAST_8_CHARACTERS: "AT_LEAST_8_CHARACTERS",
  ONE_SPECIAL_CHARACTER: "ONE_SPECIAL_CHARACTER",
  EMAIL_SEND_SUCCESSFULLY: "EMAIL_SEND_SUCCESSFULLY",
  PASSWORD_DOSE_NOT_MATCH: "PASSWORD_DOSE_NOT_MATCH",
  UPPERCASE_AND_LOWERCASE_CHARACTER: "UPPERCASE_AND_LOWERCASE_CHARACTER",
  NEW_PASSWORD: "NEW_PASSWORD",
  CONFIRM_PASSWORD: "CONFIRM_PASSWORD",
  OLD_PASSWORD: "OLD_PASSWORD",

  PERMISSIONS: "PERMISSIONS",
  TICKET: "TICKET",
  LOGO: "LOGO",
  UPLOAD: "UPLOAD",
  SOME_DESCRIPTIONS: "SOME_DESCRIPTIONS",
  PRINT_LOGO_ON_TICKET: "PRINT_LOGO_ON_TICKET",
  MARKET_NAME_ON_THE_TICKET: "MARKET_NAME_ON_THE_TICKET",
  EXPECTED_WIN: "EXPECTED_WIN",
  EXPECTED_MAX_WIN: "EXPECTED_MAX_WIN",
  SHORT_TICKET_INFO: "SHORT_TICKET_INFO",
  MATCH_ID: "MATCH_ID",
  MAX_WIN: "MAX_WIN",
  TOTAL_BET: "TOTAL_BET",
  MAX_PAYOUT_PER_BET: "MAX_PAYOUT_PER_BET",
  MAX_WINNING_PAYOUT: "MAX_WINNING_PAYOUT",
  SCAN_TO_CHECK_FAIRNESS: "SCAN_TO_CHECK_FAIRNESS",
  PREVIEW: "PREVIEW",
  COMMENT_AREA: "COMMENT_AREA",
  POSSIBLE_WIN_AMOUNT: "POSSIBLE_WIN_AMOUNT",
  ARE_YOU_SURE_YOU_WANT_TO_SAVE_CHANGE: "ARE_YOU_SURE_YOU_WANT_TO_SAVE_CHANGE",
  SHOW_QR: "SHOW_QR",
  SHOW_COMMENT: "SHOW_COMMENT",
  GAMES_CONTROL: "GAMES_CONTROL",
};

export { TRANSLATION_KEYS as T };
