import { InputsSection } from "../../../../components/shared";
import { T } from "../../../../../constants";
import { useTranslation } from "../../../../../hooks";

interface EditGameControlFormProps {}

export const EditGameControlForm: React.FC<EditGameControlFormProps> = ({}) => {
  const t = useTranslation();

  return (
    <div className="page-info-holder">
      <InputsSection title={T.GAMES_CONTROL}>
        <div></div>
      </InputsSection>
    </div>
  );
};
