export const RoutePaths = {
  Empty: "/",
  Login: "/login",
  ForgotPassword: "/forgot-password",
  NewPassword: "/new-password/login",
  GameControl: "/game-control",
  ResetPassword: "/reset-password/login",
  Dashboard: "/dashboard",
  BusinessSettings: "/business-settings",
  Businesses: "/businesses",
  BetHistory: "/bet-history",
  Partners: "/partners",
  Betshops: "/betshops",
  Managers: "/managers",
  Cashiers: "/cashiers",
  Configurations: "/configurations",
  Transactions: "/transaction-history",
  Integration: "/integration",
  WildCard: "/*",
};
